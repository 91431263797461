import React, { useEffect, useContext } from 'react';
import { CheckoutContext, CheckoutContextProps } from './context/CheckoutContext';
import { useSearchParams } from 'react-router-dom';

const DynamicGTMContainer = () => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  const [searchParams] = useSearchParams();
  const martketplaceName = searchParams.get("marketplaceName");



  useEffect(() => {

    let selectedMarketplace ;
    selectedMarketplace = company?.marketplaces?.find(marketplace => marketplace.name === martketplaceName)!;

    if(selectedMarketplace){
      updateGTMScript(selectedMarketplace?.gtmId);
      updateGTMNoscript(selectedMarketplace?.gtmId);
    } else if (company?.gtmId) {
      updateGTMScript(company.gtmId);
      updateGTMNoscript(company.gtmId);
    }

    const faviconUrl = company?.faviconUrl || company?.logo;
    if (faviconUrl) {
      updateFavicon(faviconUrl);
    }
    
  }, [company?.gtmId , company?.faviconUrl , company?.logo , martketplaceName]);


  // Function to update GTM script tag
  const updateGTMScript = (gtmId:string) => {
    const scriptTag = document.createElement('script');
    scriptTag.innerHTML = `
      (function(w,d,s,l,i){
        w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
    `;
    document.head.appendChild(scriptTag);
  };

  const updateGTMNoscript = (gtmId:string) => {
    const noscriptIframe = document.createElement('iframe');
    noscriptIframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
    noscriptIframe.height = "0";
    noscriptIframe.width = "0";
    noscriptIframe.style.display = "none";
    noscriptIframe.style.visibility = "hidden";

    // Append the noscript iframe to the document body
    document.body.appendChild(noscriptIframe);
  };

  const updateFavicon = (faviconUrl: string) => {
    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (!link) {
      link = document.createElement('link') as HTMLLinkElement;
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    link.href = faviconUrl;
  };

  return null;
};

export default DynamicGTMContainer;
